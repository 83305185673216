<template>
	<ion-page>
		<spot-header :title="(!isRevision)? shippingPackageActivity : managePackageActivity" :canGoHome="true" :canGoBack="false" @pressedGoHome="$router.push('/home')" @pressedGoBack="$router.push('/home')"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readerError="onReaderError"></web-socket-reader>
				<spot-select-custom
					:ref="projectField.name"
					:label="projectField.text"
					:allignLabel="projectField.allignLabel"
					:required="projectField.required"
					:placeholder="getPlaceHolder(projectField)"
					:enabled="getEnabled(projectField, projectField.enabled)"
					:enabledExtraButtons="getEnabled(projectField, projectField.enabledExtraButtons)"
					:value="projectField.value"
					:options="tablesData.projects"
					:optionField="projectField.refField"
					:validationState="projectField.validationState"
					:canAddItem="projectField.canAddItem"
					:canRefreshList="projectField.canRefresh"
					:canUnlock="projectField.canUnlock"
					:custom-label="customProject"
					:selectedLabel="selectedText"
					:deselectLabel="deselectText"
					:selectLabel="selectText"
					@selected="assignedProject"
					@unselected="resetProject">
				</spot-select-custom>
				<spot-select v-if="isRevision"
					:ref="packageField.name"
					:label="packageField.text"
					:allignLabel="packageField.allignLabel"
					:required="packageField.required"
					:placeholder="getPlaceHolder(packageField)"
					:enabled="getEnabled(packageField, packageField.enabled)"
					:enabledExtraButtons="getEnabled(packageField, packageField.enabledExtraButtons)"
					:value="packageField.value"
					:options="tablesData.packages"
					:optionField="packageField.refField"
					:validationState="packageField.validationState"
					:canAddItem="packageField.canAddItem"
					:canRefreshList="packageField.canRefresh"
					:canUnlock="packageField.canUnlock"
					:selectedLabel="selectedText"
					:deselectLabel="deselectText"
					:selectLabel="selectText"
					@selected="assignedPackage"
					@unselected="resetPackage">
				</spot-select>
				<take-photo v-if="isRevision"
					:enabled="getEnabled(picturesArea, picturesArea.enabled)"
					:srcImage="picturesArea.value"
					@saved="assignImage"
					@removed="clearImage"
					@cameraError="cameraNotFound">
				</take-photo>
				<spot-button v-if="isRevision"
					:ref="closePackageButton.name"
					:text="closePackageButton.text"
					:enabled="getEnabled(closePackageButton, closePackageButton.enabled)"
					:size="closePackageButton.size"
					:fill="closePackageButton.fill"
					:icon="closePackageButton.icon"
					:expand="closePackageButton.expand"
					:color="closePackageButton.color"
					@clicked="openModal(false)">
				</spot-button>
				<spot-button v-if="!isRevision"
					:ref="confirmButton.name"
					:text="confirmButton.text"
					:enabled="getEnabled(confirmButton, confirmButton.enabled)"
					:size="confirmButton.size"
					:fill="confirmButton.fill"
					:icon="confirmButton.icon"
					:expand="confirmButton.expand"
					:color="confirmButton.color"
					@clicked="manageProject">
				</spot-button>
				<ion-modal v-if="isRevision" ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<div lines="none" v-for="field in itemContents" :key="field.id" class="no-pad">
							<spot-input
								v-if="(field.inputType == 'text' || field.inputType == 'number')"
								:ref="field.name"
								:label="field.text"
								:required="field.required"
								:placeholder="getPlaceHolder(field)"
								:enabled="field.enabled"
								:validationState="field.validationState"
								:furtherInfo="field.furtherInfo"
								:value="field.value"
								:type="field.inputType"
								@textChanged="changedTextField(field.id, $event)">
							</spot-input>
						</div>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="!isEmptyRequiredItemContents && modalReadOnly == false"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<spot-button
					fill="outline"
					size="large"
					color="primary"
					:enabled="true"
					text="Simulate Scan"
					@clicked="onReadedBarcode('C11-423005','barcode')">
				</spot-button>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

		<spot-footer></spot-footer>
	</ion-page>
</template>

<style scoped>
	ion-modal {
		--height: 80%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-medium);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
	ion-textarea {
		font-size: 12px;
	}
	.flex-row-center-container {
		margin-top: 0.2em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.no-pad {
		margin-top: 0px;
		margin-bottom: 0px;
		--padding-top: 0px;
		--padding-bottom: 0px;
		--inner-padding-top: 0px;
		--inner-padding-bottom: 0px;
		--min-height: 0px
	}
</style>

<script>
	import { IonContent, IonPage, IonProgressBar, IonModal, IonTitle, IonToolbar } from '@ionic/vue';
	import { defineComponent } from 'vue';
	import SpotButton from '../components/SpotButton.vue';
	import SpotSelect from '../components/SpotSelect.vue';
	import SpotSelectCustom from '../components/SpotSelectCustom.vue';
	import SpotInput from '../components/SpotInput.vue';
	import SpotFooter from "../components/SpotFooter.vue";
	import SpotHeader from "../components/SpotHeader.vue";
	import TakePhoto from "../components/TakePhoto.vue";
	import WebSocketReader from '../components/WebSocketReader.vue';
	import { /* checkUserSession, confirmYesNo, insertValue, */ settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';
	import { searchProjects, searchPackages, searchProjectTypology, tablesData, updatePackage, updatePackageFormData } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "SelectProjectForPackages",
		props: {
			pageTitle: { type: String, default: 'Seleziona Progetto' },
			managePackageActivity: { type: String, default: 'Gestisci Colli' }, //Chiusura Colli
			shippingPackageActivity: { type: String, default: 'Carico Camion' },
			pickingListTitle: { type: String, default: 'placeholder.picking_list' },
			selectedText: { type: String, default: 'Selezionato' },
			deselectText: { type: String, default: 'annulla' },
			selectText: { type: String, default: 'seleziona' },
			// noOrdersText: { type: String, default: 'Nessun Ordine presente' },
			// newOrderText: { type: String, default: 'Nuovo Ordine' },
			// editOrderText: { type: String, default: 'Modifica Ordine'}, //'Modifica Ordine: '
			// showOrderText: { type: String, default: 'Visualizza Ordine'}, //'Visualizza Ordine: '
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			messageSearchProjectTitle: { type: String, default: 'Ricerca Progetto' },
			messageSearchProjectNotFound: { type: String, default: 'Progetto non trovato!' },
			messageSearchPackageTitle: { type: String, default: 'Ricerca Collo' },
			messageSearchPackageNotFound: { type: String, default: 'Collo non trovato!' },
			// messageTitleCheckTAG: { type: String, default: 'Controllo TAG' },
			// warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			// warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			messageTitleReader: { type: String, default: 'Lettura Barcode' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode' },
			messageTitleUpdate: { type: String, default: "Modifica stato Collo" },
			successClosePackageMessage: { type: String, default: "Collo aggiornato allo stato Chiuso." },
			// confirmDeleteTitle: { type: String, default: 'Conferma Cancellazione' },
			// confirmDeleteMessage: { type: String, default: 'Confermi la cancellazione dell\'ordine corrente?' },
			messageTitleCamera: { type: String, default: "Fotocamera" },
			warningCameraNotFound: { type: String, default: "Camera del dispositivo non presente o non utilizzabile!" },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonModal,
			IonTitle,
			IonToolbar,
			SpotHeader,
			SpotFooter,
			SpotSelectCustom,
			SpotSelect,
			SpotButton,
			SpotInput,
			TakePhoto,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				project: 0,
				package: 1,
				pictures: 2,
				closePackage: 3,
				confirm: 4,
			},
			fields: [
				{ id: 0, name: 'project', text: 'Progetto', enabled: null, enabledExtraButtons: null, allignLabel: true, placeholder: 'Seleziona Progetto', emptyValue: 'Nessuno', inputType: 'combo', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 1, name: 'package', text: 'Collo', enabled: false, enabledExtraButtons: true, allignLabel: true, placeholder: 'Seleziona Collo', emptyValue: 'Nessuno', inputType: 'select', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 2, name: 'pictures', text: 'Immagini', enabled: null, allignLabel: false, placeholder: 'Aggiungi Foto', inputType: "camera", defaultValue: null, value: null, required: true, refField: null, validationState: null },
				{ id: 3, name: 'closePackage', text: 'Chiudi Collo', enabled: null, inputType: 'button', size: 'default', fill: 'solid', expand: 'block', icon: 'gift', color: 'primary' },
				{ id: 4, name: 'confirm', text: 'Procedi', enabled: null, inputType: 'button', size: 'default', fill: 'solid', expand: 'block', icon: 'bagAdd', color: 'primary' },
			],
			itemContent: {
				width: 0,
				depth: 1,
				height: 2,
				weight: 3,
			},
			itemContents: [
				{ id: 0, name: 'width', text: 'Larghezza', enabled: true, placeholder: 'Inserisci Larghezza (mm)', inputType: 'number', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 1, name: 'depth', text: 'Profondità', enabled: true, placeholder: 'Inserisci Profondità (mm)', inputType: 'number', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 2, name: 'height', text: 'Altezza', enabled: true, placeholder: 'Inserisci Altezza (mm)', inputType: 'number', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 3, name: 'weight', text: 'Peso', enabled: true, placeholder: 'Inserisci Peso (kg)', inputType: 'number',  defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
			],
			isOpenModal: false,
			modalTitle: "Chiusura Collo",
			modalReadOnly: null,
			// currentFieldId: 0,
			lastBarcode: null,
			isIncoming: null,
			isOutgoing: null,
			isPicking: null,
			isRevision: null,
			isLocked: null,
			hasChangedPhoto: false,
			settings,
			// checkUserSession,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			writeLog,
			searchProjectTypology,
			searchProjects,
			searchPackages,
			updatePackage,
			updatePackageFormData,
			tablesData,
		}),
		computed: {
			// currField() { return this.fields[this.currentFieldId] },
			projectField() { return this.fields[this.field.project] },
			packageField() { return this.fields[this.field.package] },
			picturesArea() { return this.fields[this.field.pictures] },
			closePackageButton() { return this.fields[this.field.closePackage] },
			confirmButton() { return this.fields[this.field.confirm] },
			isEmptyRequiredFields() {
				let ret = false;
				this.fields.forEach(element => {if (element.required && !element.value) ret=true});
				return ret;
			},
			isEmptyRequiredItemContents() {
				let ret = false;
				this.itemContents.forEach(element => {if (element.required && !element.value) ret=true});
				return ret;
			},
		},
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.isOpenModal = false;
			this.isActive=false;
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isIncoming = (/true/i).test(this.$route.query.incoming);
				this.isOutgoing = (/true/i).test(this.$route.query.outgoing);
				this.isPicking = (/true/i).test(this.$route.query.picking);
				this.isRevision = (/true/i).test(this.$route.query.revision);
				this.isLocked = (/true/i).test(this.$route.query.locked);
				if (!this.isRevision) {
					this.packageField.required = false;
					this.picturesArea.required = false;
				}
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.fields.forEach(element => (element.required == true? (element.value == null ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.isOpenModal = false;
				this.modalReadOnly = null;
				this.lastBarcode = null;
				this.hasChangedPhoto = false;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			getEnabled(element, defaultState) {
				let ret = false;
				// if(this.isActive == true) {
				if (defaultState != null) ret = defaultState;
				else {
					switch (element.id) {
						// case this.field.project:
						case this.field.project:
							ret = !!tablesData.projects
							break;
						case this.field.package:
							ret = !!this.projectField.value
							break;
						case this.field.pictures:
							// ret = !!this.projectField.value && !! this.documentField.value && this.referenceItemField.value
							//ret = !this.isEmptyRequiredFields
							ret = true // need to be true becouse if camera start as false and become true after, doens't work
							break;
						case this.field.closePackage:
							// ret = !!this.projectField.value && !! this.documentField.value && this.referenceItemField.value
							ret = !this.isEmptyRequiredFields
							break;
						case this.field.confirm:
							// ret = !!this.projectField.value && !! this.documentField.value && this.referenceItemField.value
							ret = !this.isEmptyRequiredFields
							break;
					}
				}
				// }
				return ret
			},
			async getCustomLabel(element) {
				let ret = null;
				// if(this.isActive == true) {
					switch (element.id) {
						case this.field.project:
							ret = this.customProject
							break;
						// case this.field.confirm:
					}
				// }
				return ret
			},
			// Database
			async getAllTablesData() {
				this.tablesData.projects = [];
				this.tablesData.currentProject = null;
				this.tablesData.currentProjectTypology = null;
				if (this.isIncoming == true) await this.searchProjectTypology(this.supplierOrderTitle);
				if (this.isOutgoing == true) await this.searchProjectTypology(this.customerOrderTitle);
				if (this.isPicking == true) await this.searchProjectTypology(this.pickingListTitle);
				await this.searchProjects(this.tablesData.currentProjectTypology.id);
			},
			customProject ({ name, customer }) {
				return name + " - " + (!customer ? "" : customer.name)
			},
			prepareUpdatePackage() {
				let myRecord = {
					package: {
						width: (!this.itemContents[this.itemContent.width].value)? null: parseFloat(this.itemContents[this.itemContent.width].value),
						height: (!this.itemContents[this.itemContent.height].value)? null: parseFloat(this.itemContents[this.itemContent.height].value),
						depth: (!this.itemContents[this.itemContent.depth].value)? null: parseFloat(this.itemContents[this.itemContent.depth].value),
						weight: (!this.itemContents[this.itemContent.width].value)? null: parseFloat(this.itemContents[this.itemContent.weight].value),
						lock_version: this.tablesData.currentPackage.lock_version,
						closed: true,
					}
				};
				return myRecord;
			},
			prepareUpdateFormPackage() {
				let myRecord = new FormData();
				if (this.itemContents[this.itemContent.width].value) myRecord.append('packages[width]', parseFloat(this.itemContents[this.itemContent.width].value));
				if (this.itemContents[this.itemContent.height].value) myRecord.append('packages[height]', parseFloat(this.itemContents[this.itemContent.height].value));
				if (this.itemContents[this.itemContent.depth].value) myRecord.append('packages[depth]', parseFloat(this.itemContents[this.itemContent.depth].value));
				if (this.itemContents[this.itemContent.weight].value) myRecord.append('packages[weight]', parseFloat(this.itemContents[this.itemContent.weight].value));
				myRecord.append('packages[lock_version]', this.tablesData.currentPackage.lock_version);
				myRecord.append('packages[closed]', true);
				if (this.hasChangedPhoto || !!this.picturesArea.value) myRecord.append('packages[pictures][]', this.fields[this.field.pictures].value);
				return myRecord;
			},
			// Actions
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			assignImage(file) {
				this.hasChangedPhoto = true;
				this.picturesArea.value = file;
			},
			clearImage() {
				this.picturesArea.value = null;
			},
			cameraNotFound() {
				this.showMsgWarning(
					this.messageTitleCamera,
					this.warningCameraNotFound);
			},
			openModal(isReadOnly) {
				this.modalReadOnly = isReadOnly;
				this.itemContents.forEach(element => element.value = element.defaultValue);
				this.itemContents.forEach(element => (element.required == true? (element.value == null ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.isOpenModal = true;
			},
			async closeModal(saveRecord) {
				if (saveRecord == true) {
     let updatePackageState = null;
					if (!this.picturesArea.value) updatePackageState = await this.updatePackage(this.fields[this.field.package].value.id, this.prepareUpdatePackage(), false);
					else updatePackageState = await this.updatePackageFormData(this.fields[this.field.package].value.id, this.prepareUpdateFormPackage(), false);
					if (updatePackageState == true) {
      if (this.isRevision) {
       this.packageField.value = this.packageField.defaultValue;
       this.picturesArea.value = this.picturesArea.defaultValue;
       this.tablesData.packages = [];
       await this.searchPackages(this.tablesData.currentProject.id, false, false);
      }
      this.showMsgInfo(this.messageTitleUpdate,this.successClosePackageMessage);
      this.$router.push("/home");
					}
				}
				this.isOpenModal = false;
			},
			async modalDismissed() {
				this.itemContents.forEach(element => {element.value = element.defaultValue});
			},
			changedTextField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			async assignedProject(value) {
				this.tablesData.currentProject = value;
				if (this.isRevision) await this.searchPackages(this.tablesData.currentProject.id, false, false);
				this.projectField.value = value;
				this.projectField.validationState = true;
				if (this.isRevision) this.resetPackage();
			},
			resetProject() {
				this.projectField.value = this.projectField.defaultValue;
				this.tablesData.currentProject = null;
				this.projectField.validationState = false;
				if (this.isRevision) this.resetPackage();
			},
			async assignedPackage(value) {
				this.tablesData.currentPackage = value;
				this.packageField.value = value;
				this.packageField.validationState = true;
			},
			resetPackage() {
				this.packageField.value = this.packageField.defaultValue;
				this.tablesData.currentPackage = null;
				this.packageField.validationState = false;
			},
			manageProject() {
				// if (this.isRevision) 
				this.$router.push("/package/management/" + this.tablesData.currentProject.id + 
					"?incoming="+this.isIncoming+
					"&outgoing="+this.isOutgoing+
					"&picking="+this.isPicking+
					"&revision="+this.isRevision+
					"&locked="+this.isLocked
				);
			},
			async committedBarcode() {
				let foundElement = null;
				if (!this.projectField.value) {
					this.tablesData.projects.some(element => {
						if (element.name == this.lastBarcode) {
							foundElement = element;
							return true;
						}
					});
					if (!foundElement) this.showMsgWarning(this.messageSearchProjectTitle, this.messageSearchProjectNotFound);
					else await this.assignedProject(foundElement);
				}
				// else if (!this.packageField.value) {
				else {
					this.tablesData.packages.some(element => {
						if (element.name == this.lastBarcode) {
							foundElement = element;
							return true;
						}
					});
					if (!foundElement) this.showMsgWarning(this.messageSearchPackageTitle, this.messageSearchPackageNotFound);
					else this.assignedPackage(foundElement);
				}
			},
		},
	});
</script>