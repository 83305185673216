import { checkUserSession, settings } from "../plugins/common.js";
import { deleteData, getData, readData, searchQueryData, searchJsonData, updateData, updateFormData, writeData } from "./database.js";

export var tablesData = {
	currentUser: null,
	currentLocation: null,
	currentDocument: null,
	currentReferenceItem: null,
	currentMeasureUnit: null,
	currentAdditionalBarcode: null,
	currentItem: null,
	currentProjectTypology: null,
	currentProject: null,
	currentProjectRow: null,
	currentPick: null,
	currentTrack: null,
	currentPackage: null,
	currentPrinter: null,
	currentPrintTemplate: null,
	printerActionResult: null,
	locations: [],
	documents: [],
	referenceItems: [],
	measureUnits: [],
	additionalBarcodes: [],
	items: [],
	projectTypologies: [],
	projects: [],
	projectRows: [],
	picks: [],
	tracks:[],
	packages: [],
	printers: [],
	printTemplates: [],
}
export var tablesToBeRefreshed = {
	locations: false,
	documents: false,
	referenceItems: false,
	measureUnits: false,
	additionalBarcodes: false,
	items: false,
	projectTypologies: false,
	projects: false,
	projectRows: false,
	picks: false,
	tracks: false,
	packages: false,
	printers: false,
	printTemplates: false,
}
var retState = null

export async function hasPrinter() {
	await getData("printers")
	//.then(data => { (data.length > 0) ? settings.printerManagement = true : settings.printerManagement = false; tablesData.printers = data; retState =  true; })
	.then(data => { settings.printerManagement = (data.length > 0); tablesData.printers = data; retState =  true; })
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getLocations() { 
	await getData("locations")
	.then(data => { tablesData.locations = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getDocuments() { 
	await getData("documents")
	.then(data => { tablesData.documents = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getReferenceItems() { 
	await getData("documents")
	.then(data => { tablesData.referenceItems = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getMeasureUnits() {
	await getData("measure_units")
	.then(data => { tablesData.measureUnits = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getAdditionalBarcodes() {
	await getData("additional_barcodes")
	.then(data => { tablesData.additionalBarcodes = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getItems() {
	await getData("items")
	.then(data => { tablesData.items = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getProjectTypologies() {
	await getData("project_typologies")
	.then(data => { tablesData.projectTypologies = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getProjects() {
	await getData("projects")
	.then(data => { tablesData.projects = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getProjectRows() {
	await getData("project_rows")
	.then(data => { tablesData.projectRows = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getPicks() {
	await getData("picks")
	.then(data => { tablesData.picks = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getTracks() {
	await getData("tracks")
	.then(data => { tablesData.tracks = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getPackages() {
	await getData("packages")
	.then(data => { tablesData.packages = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getPrinters() {
	await getData("printers")
	.then(data => { tablesData.printers = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getPrintTemplates() {
	await getData("print_templates")
	.then(data => { tablesData.printTemplates = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchDocuments(project_id = null, closed = null, partial = null) {
	let mySearch = {
		params: {
			// 'q[project_id_eq]': project_id,
			'q[parent_id_null]': true,
		}
	};
	(project_id != null)? mySearch.params['q[project_id_eq]'] = project_id : mySearch.params['q[project_id_null]'] = true;
	if (closed != null) mySearch.params['q[closed_true]'] = closed
	if (partial != null) mySearch.params['q[partial_true]'] = partial
	await searchQueryData("documents", mySearch)
	.then(data => { tablesData.documents = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchReferenceItems(project_id = null, parent_id, closed = null, partial = null) {
	let mySearch = {
		params: {
			// 'q[project_id_eq]': project_id,
			'q[parent_id_eq]': parent_id,
		}
	};
	(project_id != null)? mySearch.params['q[project_id_eq]'] = project_id : mySearch.params['q[project_id_null]'] = true;
	if (closed != null) mySearch.params['q[closed_true]'] = closed
	if (partial != null) mySearch.params['q[partial_true]'] = partial
	await searchQueryData("documents", mySearch)
	.then(data => { tablesData.referenceItems = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchProjects(project_typology_id, store = null, prepared = null, delivered = null, closed = null, partial = null, suspended = null, user_id = null, sort_field = null) {
	let mySearch = {
		params: {
			'q[project_typology_id_eq]': project_typology_id,
		}
	};
	if (store != null) mySearch.params['q[store_true]'] = store
	if (prepared != null) mySearch.params['q[prepared_true]'] = prepared
	if (delivered != null) mySearch.params['q[delivered_true]'] = delivered
	if (closed != null) mySearch.params['q[closed_true]'] = closed
	if (partial != null) mySearch.params['q[partial_true]'] = partial
	if (suspended != null) mySearch.params['q[suspended_true]'] = suspended
	if (user_id != null) mySearch.params['q[user_id_eq]'] = user_id
	if (sort_field != null) mySearch.params['q[s]'] = sort_field
	await searchQueryData("projects", mySearch)
	.then(data => { tablesData.projects = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchProjectRows(project_id, location_id = null, filter = null, package_id = null) {
	let mySearch = {
		params: {
			'q[project_id_eq]': project_id,
		}
	};
	if (location_id) mySearch.params['q[location_id_eq]'] = location_id
	if (filter) mySearch = filter
	if (package_id) mySearch.params['q[package_id_eq]'] = package_id
	await searchQueryData("project_rows", mySearch)
	.then(data => { tablesData.projectRows = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchPicks(project_id, location_id = null, filter = null) {
	let mySearch = {
		params: {
			'q[project_id_eq]': project_id,
		}
	};
	if (location_id) mySearch.params['q[location_id_eq]'] = location_id
	if (filter) mySearch = filter
	await searchQueryData("picks", mySearch)
	.then(data => { tablesData.picks = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchPicksWithDocuments(project_id, document_id = null) {
	let mySearch = {
		params: {
			'q[project_id_eq]': project_id,
		}
	};
	!document_id ? mySearch.params['q[document_id_null]'] = true  : mySearch.params['q[document_id_eq]'] = document_id;
	await searchQueryData("picks", mySearch)
	.then(data => { tablesData.picks = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchTracks(project_row_id) {
	let mySearch = {
		params: {
			'q[project_row_id_eq]': project_row_id,
		}
	};
	await searchQueryData("tracks", mySearch)
	.then(data => { tablesData.tracks = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchPackages(project_id = null, closed = null, shipped = null) {
	let mySearch = {
		params: {
		}
	};
	!project_id ? mySearch.params['q[project_id_null]'] = true  : mySearch.params['q[project_id_eq]'] = project_id;
	if (closed != null) mySearch.params['q[closed_eq]'] = closed
	if (shipped != null) mySearch.params['q[shipped_eq]'] = shipped
	await searchQueryData("packages", mySearch)
	.then(data => { tablesData.packages = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function jsonSearchProjects(json) {
	await searchJsonData("projects", json)
	.then(data => { tablesData.projects = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function jsonSearchProjectRows(json) {
	await searchJsonData("project_rows", json)
	.then(data => { tablesData.projectRows = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function jsonSearchPicks(json) {
	await searchJsonData("picks", json)
	.then(data => { tablesData.picks = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function createLocation(objData, refreshData = true) {
	await writeData("locations", objData)
	.then(async (data) => {	if (refreshData == true) await getLocations();	tablesData.currentLocation = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function createItem(objData, refreshData = true) {
	await writeData("items", objData)
	.then(async (data) => {	if (refreshData == true) await getItems();	tablesData.currentItem = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function createProject(objData, refreshData = true) {
	await writeData("projects", objData)
	.then(async (data) => {	if (refreshData == true) await getProjects();	tablesData.currentProject = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function createProjectRow(objData, refreshData = true) {
	await writeData("project_rows", objData)
	.then(async (data) => {	if (refreshData == true) await getProjectRows();	tablesData.currentProjectRow = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function createPick(objData, refreshData = true) {
	await writeData("picks", objData)
	.then(async (data) => {	if (refreshData == true) await getPicks();	tablesData.currentPick = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function createTrack(objData, refreshData = true) {
	await writeData("tracks", objData)
	.then(async (data) => {	if (refreshData == true) await getTracks();	tablesData.currentTrack = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function createPackage(objData, refreshData = true) {
	await writeData("packages", objData)
	.then(async (data) => {	if (refreshData == true) await getPackages();	tablesData.currentPackage = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getLocation(id) {
	await readData("locations", id)
	.then(data => { tablesData.currentLocation = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getDocument(id) {
	await readData("documents", id)
	.then(data => { tablesData.currentDocument = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getReferenceItem(id) {
	await readData("documents", id)
	.then(data => { tablesData.currentReferenceItem = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getAdditionalBarcode(id) {
	await readData("additional_barcodes", id)
	.then(data => { tablesData.currentAdditionalBarcode = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getItem(id) {
	await readData("items", id)
	.then(data => { tablesData.currentItem = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getProject(id) {
	await readData("projects", id)
	.then(data => { tablesData.currentProject = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getProjectRow(id) {
	await readData("project_rows", id)
	.then(data => { tablesData.currentProjectRow = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getPick(id) {
	await readData("picks", id)
	.then(data => { tablesData.currentPick = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getPackage(id) {
	await readData("packages", id)
	.then(data => { tablesData.currentPackage = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getPrinter(id) {
	await readData("printers", id)
	.then(data => { tablesData.currentPrinter = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function getPrintTemplate(id) {
	await readData("print_templates", id)
	.then(data => { tablesData.currentPrintTemplate = data; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function updateDocument(id, objData, refreshData = true) {
	await updateData("documents", id, objData)
	.then(async (data) => {	if (refreshData == true) await getDocuments();	tablesData.currentDocument = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function updateItem(id, objData, refreshData = true) {
	await updateData("items", id, objData)
	.then(async (data) => {	if (refreshData == true) await getItems();	tablesData.currentItem = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function updateProject(id, objData, refreshData = true) {
	await updateData("projects", id, objData)
	.then(async (data) => {	if (refreshData == true) await getProjects();	tablesData.currentProject = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function updateProjectRow(id, objData, refreshData = true) {
	await updateData("project_rows", id, objData)
	.then(async (data) => {	if (refreshData == true) await getProjectRows();	tablesData.currentProjectRow = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function updatePick(id, objData, refreshData = true) {
	await updateData("picks", id, objData)
	.then(async (data) => {	if (refreshData == true) await getPicks();	tablesData.currentPick = data;	retState = true;})
	.catch(error => { checkUserSession(error, "warning"); retState = false; });
	return retState;
}
export async function updatePackage(id, objData, refreshData = true) {
	await updateData("packages", id, objData)
	.then(async (data) => {	if (refreshData == true) await getPackages();	tablesData.currentPackage = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function updatePrinter(id, objData, refreshData = true) {
	await updateData("printers", id, objData)
	.then(async (data) => {	if (refreshData == true) await getPrinters();	tablesData.currentPrinter = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function updatePackageFormData(id, objData, refreshData = true) {
	await updateFormData("packages", id, objData)
	.then(async (data) => {	if (refreshData == true) await getPackages();	tablesData.currentPackage = data;	retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchAdditionalBarcode(code) {
	let mySearch = {
		params: {
			'q[code_eq]': code,
		}
	};
	await searchQueryData("additional_barcodes", mySearch)
	.then(data => { tablesData.currentAdditionalBarcode = data[0]; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchItem(code = null, barcode = null) {
	let mySearch = {
		params: {
		}
	};
	if (code) mySearch.params['q[code_eq]'] = code
	if (barcode) mySearch.params['q[barcode_eq]'] = barcode
	await searchQueryData("items", mySearch)
	.then(data => { tablesData.currentItem = data[0]; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchProjectTypology(title) {
	let mySearch = {
		params: {
			'q[title_eq]': title,
		}
	};
	await searchQueryData("project_typologies", mySearch)
	.then(data => { tablesData.currentProjectTypology = data[0]; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchProject(name) {
	let mySearch = {
		params: {
			'q[name_eq]': name,
		}
	};
	await searchQueryData("projects", mySearch)
	.then(data => { tablesData.currentProject = data[0]; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchPick(project_row_id) {
	let mySearch = {
		params: {
			'q[project_row_id_eq]': project_row_id,
		}
	};
	await searchQueryData("picks", mySearch)
	.then(data => { tablesData.currentPick = data[0]; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function searchPrinter(isDefaulPrinter) {
	let mySearch = {
		params: {
			'q[default_eq]': isDefaulPrinter,
		}
	};
	await searchQueryData("printers", mySearch)
	.then(data => { tablesData.currentPrinter = data[0]; retState =  true;})
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });
	return retState;
}
export async function deleteItem(id, refreshData = true) {
	await deleteData("items", id)
	.then(async () => {	if (refreshData == true) await getItems();	tablesData.currentItem = null; retState =  true; })
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });	
	return retState;
}
export async function deleteProject(id, refreshData = true) {
	await deleteData("projects", id)
	.then(async () => {	if (refreshData == true) await getProjects();	tablesData.currentProject = null; retState =  true; })
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });	
	return retState;
}
export async function deleteProjectRow(id, refreshData = true) {
	await deleteData("project_rows", id)
	.then(async () => {	if (refreshData == true) await getProjectRows();	tablesData.currentProjectRow = null; retState =  true; })
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });	
	return retState;
}
export async function deletePick(id, refreshData = true) {
	await deleteData("picks", id)
	.then(async () => {	if (refreshData == true) await getPicks();	tablesData.currentPick = null; retState =  true; })
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });	
	return retState;
}
export async function deletePackage(id, refreshData = true) {
	await deleteData("packages", id)
	.then(async () => {	if (refreshData == true) await getPackages();	tablesData.currentPackage = null; retState =  true; })
	.catch(error => { checkUserSession(error, "warning"); retState =  false; });	
	return retState;
}
export async function executePrinterAction(destinationUrl) {
	retState = false;
	await getData(destinationUrl)
	.then(data => { tablesData.printerActionResult = data; tablesData.printerIsOnErrorState = false; /*retState = true;*/ })
	//.catch(error => { checkUserSession(error, "warning"); retState = false; });
	// return retState;
}
export async function getAllTablesData() {
	if (tablesToBeRefreshed.locations) await getLocations();
	if (tablesToBeRefreshed.documents) await getDocuments();
	if (tablesToBeRefreshed.referenceItems) await getReferenceItems();
	if (tablesToBeRefreshed.measureUnits) await getMeasureUnits();
	if (tablesToBeRefreshed.additionalBarcodes) await getAdditionalBarcodes();
	if (tablesToBeRefreshed.items) await getItems();
	if (tablesToBeRefreshed.projectTypologies) await getProjectTypologies();
	if (tablesToBeRefreshed.projects) await getProjects();
	if (tablesToBeRefreshed.projectRows) await getProjectRows();
	if (tablesToBeRefreshed.picks) await getPicks();
	if (tablesToBeRefreshed.tracks) await getTracks();
	if (tablesToBeRefreshed.packages) await getPackages();
	if (tablesToBeRefreshed.printers) getPrinters();
	if (tablesToBeRefreshed.printTemplates) await getPrintTemplates();
}
