<template>
	<ion-page>
		<spot-header :title="feName" :canGoHome="false" :canGoBack="true" @pressedGoHome="$router.push('/home')" @pressedGoBack="$router.push('/login')"></spot-header>
		<spot-page-menu v-if="enabledItems.length!=1" :menuItems="enabledItems" :isReady="gettedModules" @selected="actionGoTo"></spot-page-menu>
		<spot-footer></spot-footer>
	</ion-page>
</template>

<style scoped></style>

<script>
import { feName } from "@/plugins/variables.js";
import { IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import SpotFooter from "@/components/SpotFooter.vue";
import SpotHeader from "@/components/SpotHeader.vue";
import SpotPageMenu from "@/components/SpotPageMenu.vue";
import { writeLog } from '../plugins/common.js';

export default defineComponent({
	name: "HomePage",
	components: {
		IonPage,
		SpotHeader,
		SpotPageMenu,
		SpotFooter,
	},
	data: () => ({
		menuItems: [
			{ id: 0, name: 'NewPackage', text: 'PREPARAZIONE COLLI', link: "/project/selection" + "?incoming=false&outgoing=false&picking=true&revision=false&locked=false", icon: "bagAdd", enabled: null },
			{ id: 1, name: 'CheckPackage', text: 'REVISIONE/CHIUSURA COLLI', link: "/package/selection" + "?incoming=false&outgoing=false&picking=true&revision=true&locked=false", icon: "bagCheck", enabled: null },
			{ id: 2, name: 'Shipping', text: 'CARICO CAMION', link: "/package/selection" + "?incoming=false&outgoing=false&picking=true&revision=false&locked=false", icon: "bus", enabled: null },
		],
		enabledItems: [],
		gettedModules: false,
		feName,
		writeLog,
	}),
	async ionViewWillEnter() {
		await this.getAvailableModules();
		if (this.enabledItems.length==1) this.$router.push(this.enabledItems[0].link);
	},
	ionViewWillLeave() {
		this.gettedModules = false;
		// Do Nothing
	},
	methods: {
		// Settings & StartUp
		async getAvailableModules() {
			this.gettedModules = false;
			this.enabledItems = this.menuItems;
			this.gettedModules = true;
		},
		// Database
		// Actions
		actionGoTo(target) {
			this.writeLog(target);
			switch (target.id) {
				case 0:
					this.$router.push("/project/selection" + "?incoming=false&outgoing=false&picking=true&revision=false&locked=false");
					break;
				case 1:
					this.$router.push("/package/selection" + "?incoming=false&outgoing=false&picking=true&revision=true&locked=false");
					break;
				case 2:
					this.$router.push("/package/selection" + "?incoming=false&outgoing=false&picking=true&revision=false&locked=false");
					break;
			}
		}
	}
});
</script>
